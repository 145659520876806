.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo {
   
    background: #1c1c1c;
    border-radius: 50%;
    padding: 5px;
    height: 40px;  
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.chat-list {
  
  

  height: calc(100vh - 150px);
  overflow-y: auto;
  margin-bottom: 15px;
  border: 1px solid rgb(68, 68, 73) !important;
  border-radius: 24px;
  line-height: 5px;
  &::-webkit-scrollbar {
    display: none;
  }
 
  &::-webkit-scrollbar-thumb {
    
      box-shadow: #858282;
    
    border-radius: 5px;
  }
  
}
.chat-popup {
  
  

  height: calc(100vh - 230px);
  overflow-y: auto;
  margin-left: 5px;
  margin-bottom: 15px;
  border: 1px solid rgb(68, 68, 73) !important;
  border-radius: 24px;
  line-height: 5px;
  
}

.chat-message{
  display: flex;
  
  justify-content: space-between;
  border: 1px solid rgb(68, 68, 73) !important;
  border-radius: 24px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.chat-message-left{
  vertical-align: middle;
  flex: 0 1 auto;
}
.chat-message-right{
  flex: 0 1 auto;
}
.chat-message-middle{
  flex: 1 1 auto;
  margin: 0 10px;
}
.chat-message-middle input {
 
 background: transparent;
  border: none;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  font: inherit;
  font-size: 14px;
  color: #fff !important;
  height: 30px;
 
}
.chat-message-middle input:focus{
  outline: none;
}

 
.hide {
  visibility: hidden;
  display: none;
}
.chat-hide {
  visibility: hidden;
  display: none;
}
.chat-typing {
  padding: 10px;
  
}

.dot-flashing {
  position: relative;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 122, 255, 1);
  color: rgba(0, 122, 255, 1);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #007aff;
  color: #007aff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #007aff;
  color: #007aff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

.chat-wrapper {
  height: 100%;
 
 
  display: flex;
  flex-flow: column;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 4px 12px rgba(71, 69, 123, 0.24), 0 2px 4px rgba(71, 69, 123, 0.12);
  border-radius: 25px;
  overflow: hidden;
  position: relative;
}
.chat-body {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    
    background-color: #2c2c2c;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    
      box-shadow: #2c2c2c;
    
    border-radius: 5px;
  }
}

.chat-body-inner {
 
  color: #3d5154;
  font-size: 13px;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  padding: 12px 8px 8px 8px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
.chat-body-inner .user_msg{
 
  background-color: #1677ff;
    color: #fff;
  
   max-width: 100%;
  padding: 10px;
  border-radius: 20px;
  text-align: end;
}
.chat-body-inner .bot_msg{
  background-color: #2d2d31;
  color: #fff;
  max-width: 100%;
  padding: 10px;
  border-radius: 20px;
  text-align: left;
}

.chat-body-inner p span{
  margin: 5px;
}

.chat-body-inner  p > span:first-child{
  margin-right: 0;
}

.chat-button {
   
  cursor: pointer;
  color: #3d5154;
} 
.chat-header{
    flex: 0 1 auto;
    padding: 5px;
    text-align: center;
  
  
}


@keyframes dot-flashing {
  0% {
    background-color: #007aff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotation 8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.ant-collapse-header {
  color: #fff !important;
  text-align: left !important;
}
.ant-collapse-content {
  text-align: left !important;
  border-top: none !important;
}